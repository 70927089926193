export default class ScrollByCell {
    initScrollHandler(el: HTMLDivElement, maxNumRows: number) {
        //------------------------------------------------------------ FUNCTIONS ------------------------------------------------------------//
        function wheelListener(e: WheelEvent){
            //this ignores horizontal laptop trackpad movements
            if (Math.abs(e.deltaY) < 10) {
                return;
            }
            if (e.ctrlKey) {
                return;
            }
            e.preventDefault();

            if (e.shiftKey){
                heatgridBody.scrollBy({
                    left: e.deltaY,
                })
            } else {
                heatgridBody.scrollBy({
                    top: e.deltaY,
                })
            }

        }

        function mouseOverCellListener(e: Event) {
            const target = e.target as HTMLElement;
            const rowColString = target.dataset.rowCol;
            if (!rowColString) {
                return;
            }
            const [rowIndexString, colIndexString] = rowColString.split("-");
            if (!rowIndexString || !colIndexString) {
                return;
            }

            const rowIndex = Number(rowIndexString);
            const colIndex = Number(colIndexString);

            if (isNaN(rowIndex) || isNaN(colIndex)) {
                return;
            }

            rowLabelsContainer.children[rowIndex].classList.add("hovered");
            // first child is an empty div for spacing
            columnLabelsContainer.children[colIndex+1].children[0].classList.add("hovered");
        }

        function mouseOutCellListener(e: Event) {
            const target = e.target as HTMLElement;
            const rowColString = target.dataset.rowCol;
            if (!rowColString) {
                return;
            }
            const [rowIndexString, colIndexString] = rowColString.split("-");
            if (!rowIndexString || !colIndexString) {
                return;
            }

            const rowIndex = Number(rowIndexString);
            const colIndex = Number(colIndexString);

            if (isNaN(rowIndex) || isNaN(colIndex)) {
                return;
            }

            rowLabelsContainer.children[rowIndex].classList.remove("hovered");
            // first child is an empty div for spacing
            columnLabelsContainer.children[colIndex+1].children[0].classList.remove("hovered");
        }

        function hideRowAndColLabels() {
            // Calculate which rows & cols are showing then show/hide corresponding labels.

            const firstRowShown = Math.round(heatgridBody.scrollTop / rowHeight);

            if (startingShownRow !== null) {
                // Hide the old ones.
                for (let i = startingShownRow; i < Math.min(startingShownRow + numRowsToDisplay, rowLabelsContainer.children.length); i++) {
                    (rowLabelsContainer.children[i] as HTMLElement).hidden = true;
                }
            } else {
                // Initial load, hide first shown labels.
                for (let i = 0; i < firstRowShown /*No need to hide just to un-hide later.*/; i++) {
                    const rowLabel = rowLabelsContainer.children[i] as HTMLElement;
                    // If it's hidden already, we must have exhausted the initially shown labels.
                    if (rowLabel.hidden) {
                        break;
                    }
                    rowLabel.hidden = true;
                }
            }
            // Show the new ones (or, when we're initializing, un-hide the initially shown).
            for (let i = firstRowShown; i < Math.min(firstRowShown + numRowsToDisplay, rowLabelsContainer.children.length); i++) {
                (rowLabelsContainer.children[i] as HTMLElement).hidden = false;
            }
            startingShownRow = firstRowShown;

            const firstColShown = Math.round(heatgridBody.scrollLeft / columnWidth) + 1;
            const exclusiveLastColumn = Math.min(firstColShown + numColsToDisplay, columnLabelsContainer.children.length);

            // There is an empty div to start (for spacing reasons) hence why we start with i=1
            for (let i = 1; i < columnLabelsContainer.children.length; i++) {
                // If the element is in the range, we should not hide it
                (columnLabelsContainer.children[i] as HTMLElement).hidden = !(i >= firstColShown && i < exclusiveLastColumn);
            }
        }

        function setHeatgridBodyDimensions() {
            //If there are fewer columns than there are spaces available, hide overflow x
            if (numColsToDisplay >= heatgridBody.children[0].children.length) {
                heatgridBody.style.overflowX = 'hidden';
                // Changing the height here and the width in the next check seems backwards but if the columns overflow and a scrollbar shows,
                // that bar goes on the bottom and changes the height of the heatgrid rather than the width. Same logic applies vise versa.
                heatgridBody.style.height = numRowsToDisplay * rowHeight + "px";
            } else { //otherwise there will be a bottom scrollbar and we need to increase the height of our heatgrid to accommodate
                heatgridBody.style.height = numRowsToDisplay * rowHeight + scrollbarWidth +  "px";
            }

            const tmpScroll = heatgridBody.scrollLeft;
            if (numRowsToDisplay >= heatgridBody.children.length) {
                heatgridBody.style.overflowY = 'hidden';
                heatgridBody.style.width = numColsToDisplay * columnWidth + "px";
            } else {
                heatgridBody.style.width = numColsToDisplay * columnWidth + scrollbarWidth + "px";
            }
            heatgridBody.scrollLeft = tmpScroll;
        }


        //------------------------------------------------------------ Code to Run on Page Load ------------------------------------------------------------//
        const scrollbarWidth = (() =>
        {
            // Adapted from https://davidwalsh.name/detect-scrollbar-width.
            // If you, dear reader, are wanting to steal this, maybe you should put it in index.ts instead.

            // Create a temporary element
            const scrollDiv = document.createElement('div');

            // Set the style to ensure the element is scrollable
            scrollDiv.style.width = '100px';
            scrollDiv.style.height = '100px';
            scrollDiv.style.overflow = 'scroll';
            scrollDiv.style.position = 'absolute';
            scrollDiv.style.top = '-9999px';

            document.body.appendChild(scrollDiv);

            // Calculate scrollbar width
            const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;

            document.body.removeChild(scrollDiv);

            return scrollbarWidth;
        })();

        let startingShownRow: number | null = null;
        const heatgridBody = el.querySelector('.heatgrid-body') as HTMLDivElement;
        const heatgridContainer = el.closest('.heatgrid-container') as HTMLDivElement;
        const columnWidth = (heatgridBody.children[0].children[0] as HTMLElement).offsetWidth  + parseInt(getComputedStyle(heatgridBody.children[0]).columnGap.slice(0, -2));
        const rowHeight   = (heatgridBody.children[0].children[0] as HTMLElement).offsetHeight + parseInt(getComputedStyle(heatgridBody).rowGap.slice(0, -2));
        const rowLabelsContainer = el.querySelector('.heatgrid-row-labels') as HTMLDivElement;
        const widthPadding = 140 /*row label width*/ + 160; /*column label overhang*/
        let numColumnsThatWillFit = Math.floor((heatgridContainer.offsetWidth - widthPadding) / columnWidth);
        let numColsToDisplay = Math.max(1, Math.min(numColumnsThatWillFit, heatgridBody.children[0].children.length)); // Make sure one column shows
        let numRowsToDisplay = Math.max(1, Math.min(maxNumRows, heatgridBody.children.length)); // Make sure one row shows

        setHeatgridBodyDimensions();

        //set the row title height equal to the height of the table
        const rowTitle = el.querySelector('#heatgrid-row-title') as HTMLElement;
        rowTitle.style.height = numRowsToDisplay * rowHeight + "px";
        const columnTitle = el.querySelector('#heatgrid-col-title') as HTMLElement;
        columnTitle.style.width = numColsToDisplay * columnWidth + "px";
        const columnLabelsContainer = el.querySelector('.heatgrid-column-labels') as HTMLDivElement;
        columnLabelsContainer.parentElement.style.width = parseInt(heatgridBody.style.width.slice(0, -2)) + 140 + "px";
        hideRowAndColLabels();

        //------------------------------------------------------------ Set Event Listeners ------------------------------------------------------------//

        const onElementResize = (width: number) => {
            numColumnsThatWillFit = Math.floor((width - widthPadding) / columnWidth);
            numColsToDisplay = Math.max(1, Math.min(numColumnsThatWillFit, heatgridBody.children[0].children.length)); // Make sure one column shows
            numRowsToDisplay = Math.max(1, Math.min(maxNumRows, heatgridBody.children.length)); // Make sure one row shows
            columnTitle.style.width = numColsToDisplay * columnWidth + "px";
            setHeatgridBodyDimensions();
            hideRowAndColLabels();
        };

        let lastContainerWidth = heatgridContainer.offsetWidth;
        let lastRect: DOMRect | null = null;
        const resizeObserver = new ResizeObserver(() => {
            const rect = heatgridContainer.getBoundingClientRect();
            // Check if the bounding rectangle has changed more than a columns width
            if (
                !lastRect ||
                (rect.width !== lastRect.width ||
                rect.height !== lastRect.height ||
                rect.top !== lastRect.top ||
                rect.left !== lastRect.left) &&
                Math.abs(lastContainerWidth - rect.width) > columnWidth
            ) {
                lastRect = rect;
                lastContainerWidth = rect.width;
                onElementResize(rect.width);
            }
        });

        resizeObserver.observe(heatgridContainer);

        for (const row of heatgridBody.children) {
            for (const cell of row.children) {
                cell.addEventListener('mouseover', mouseOverCellListener);
                cell.addEventListener("mouseout", mouseOutCellListener);
            }
        }
        heatgridBody.addEventListener('wheel', wheelListener);
        heatgridBody.addEventListener('scroll', hideRowAndColLabels);
        return {
            destroy: () => {
                heatgridBody.removeEventListener('scroll', hideRowAndColLabels);
                heatgridBody.removeEventListener('wheel', wheelListener);
                resizeObserver.disconnect();
            }
        }
    }
}
